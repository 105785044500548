import { inject, Injectable, signal } from '@angular/core';
import { sideMenuSchoolStructureItem } from './layout.component';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { filter, first, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly router = inject(Router);
  private _showSchoolScopeSelectionPopover = signal(false);
  public readonly showSchoolScopeSelectionPopover =
    this._showSchoolScopeSelectionPopover.asReadonly();

  private _showAcademicYearPopover = signal(false);
  public readonly showAcademicYearPopover =
    this._showAcademicYearPopover.asReadonly();

  private _isSideNavCollapsed = signal(false);
  public isSideNavCollapsed = this._isSideNavCollapsed.asReadonly();

  private _selectedSchoolStructureNavItem =
    signal<sideMenuSchoolStructureItem | null>(null);
  public selectedSchoolStructureNavItem =
    this._selectedSchoolStructureNavItem.asReadonly();

  private _isShowProgressBar = signal<boolean>(false);
  public isShowProgressBar = this._isShowProgressBar.asReadonly();

  private _isShowPageSpinner = signal<boolean>(false);
  public isShowPageSpinner = this._isShowPageSpinner.asReadonly();

  private _currentClassName = signal<string | null>(null);
  public currentClassName = this._currentClassName.asReadonly();

  private _currentLevelName = signal<string | null>(null);
  public currentLevelName = this._currentLevelName.asReadonly();

  private _showSplashScreen = signal(true);
  public showSplashScreen = this._showSplashScreen.asReadonly();

  constructor() {
    this.router.events
      .pipe(
        // we only care about the first route the user is navigating to on app start
        filter((e) => e instanceof NavigationEnd),
        first(),
      )
      .subscribe((e) => {
        // hide splash screen as soon as the first navigation ends
        // this is to make sure if user is visting a route or child route all routes checks (guards, resolvers, etc..) will be finished first
        this.hideSplashScreen();
      });
    // show loading spinner over content area when loading a lazy loaded feature/route
    this.router.events
      .pipe(
        filter(
          (e) =>
            e instanceof RouteConfigLoadStart ||
            e instanceof RouteConfigLoadEnd ||
            e instanceof NavigationStart ||
            e instanceof NavigationEnd ||
            e instanceof NavigationCancel,
        ),
      )
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.showPageSpinner();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          timer(500)
            .pipe(take(1))
            .subscribe(() => {
              this.hidePageSpinner();
            });
        }
      });
  }

  toggleSchoolSelectionPopover() {
    this._showSchoolScopeSelectionPopover.update((v) => !v);
  }
  updateSchoolSelectionPopover(v: boolean) {
    this._showSchoolScopeSelectionPopover.set(v);
  }

  toggleAcademicYearPopover() {
    this._showAcademicYearPopover.update((v) => !v);
  }
  updateAcademicYearPopover(v: boolean) {
    this._showAcademicYearPopover.set(v);
  }

  setSideNavCollapsed(isCollapsed: boolean) {
    this._isSideNavCollapsed.set(isCollapsed);
  }

  toggleSideNav() {
    this._isSideNavCollapsed.update((v) => !v);
  }

  setSelectedSchoolStructureNavItem(item: sideMenuSchoolStructureItem) {
    this._selectedSchoolStructureNavItem.set(item);
  }

  showProgressBar() {
    this._isShowProgressBar.set(true);
  }
  hideProgressBar() {
    this._isShowProgressBar.set(false);
  }
  toggleProgressBar() {
    this._isShowProgressBar.update((v) => !v);
  }

  updateClassName(val: string | null) {
    this._currentClassName.set(val);
  }

  updateLevelName(val: string | null) {
    this._currentLevelName.set(val);
  }

  hideSplashScreen() {
    this._showSplashScreen.set(false);
  }

  showPageSpinner() {
    this._isShowPageSpinner.set(true);
  }
  hidePageSpinner() {
    this._isShowPageSpinner.set(false);
  }
  togglePageSpinner() {
    this._isShowPageSpinner.update((v) => !v);
  }
}
